// @flow

import * as R from "ramda";
import transit from "transit-js";
import { toast } from "react-toastify";

import { backendUrl } from "src/config/firebase";
import connection from "src/db";
import fieldReader from "src/transit/checklist/field/reader";
import fieldType from "src/transit/checklist/field/types";
import fieldWriter from "src/transit/checklist/field/writer";
import * as easyFetch from "src/utils/fetch";

import type {
  UID,
  BulkUpdateProcessAPIPayload,
  ProcessTemplateId,
  FieldMappings,
  CSVFileDetails
} from "src/types";

/**
 * Fetches details of given workflow
 *
 * @param {string} id of the workflow
 * @returns {Workflow} Workflow details
 */
const getWorkflow = async (id: string) => {
  const response = await fetch(`${backendUrl}/workflow/${id}`, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  const jsonResponse = await response.json();

  const reader = transit.reader("json", { handlers: fieldReader });
  const fields = await R.map(
    field => ({
      ...reader.read(field.attrs),
      id: field.id,
      hidden: field.hidden,
      deleted: field.deleted,
      deletedBy: field?.deletedBy,
      deletedAt: field?.deletedAt,
      promptRules: field.promptRules,
      layout: field.layout || null
    }),
    jsonResponse.checklistFields
  );

  return R.mergeDeepRight(jsonResponse, { checklistFields: fields });
};

/**
 * Creates workflow template
 *
 * @param {Object} request for creating workflow template
 * @returns {Workflow} Workflow template created
 */
const createWorkflow = async (workflow: Object) => {
  const { checklistFields } = workflow;

  const encodedFields = R.map(item => ({
    attrs: transit
      .writer("json-verbose", {
        handlers: transit.map([
          fieldType[item.type],
          transit.makeWriteHandler(fieldWriter[item.type])
        ])
      })
      .write(
        new fieldType[item.type]({
          label: item.label,
          isChecked: item?.checkMark,
          settings: item.settings
        })
      ),
    promptRules: item?.promptRules,
    deleted: item?.deleted,
    hidden: item?.hidden,
    deletedBy: item?.deletedBy,
    deletedAt: item?.deletedAt
  }))(checklistFields);

  const response = await fetch(`${backendUrl}/workflow`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      ...workflow,
      checklistFields: encodedFields
    })
  });

  return response.json();
};

/**
 * Edits workflow template
 *
 * @param {Object} request for creating workflow template
 */
const editWorkflow = async (workflow: Object) => {
  const { id, checklistFields } = workflow;

  const encodedFields = R.map(item => ({
    id: item?.id,
    attrs: transit
      .writer("json-verbose", {
        handlers: transit.map([
          fieldType[item.type],
          transit.makeWriteHandler(fieldWriter[item.type])
        ])
      })
      .write(
        new fieldType[item.type]({
          label: item.label,
          isChecked: item?.checkMark,
          settings: item.settings
        })
      ),
    promptRules: item?.promptRules,
    layout: item?.layout || null,
    deleted: item?.deleted,
    hidden: item?.hidden,
    deletedBy: item?.deletedBy,
    deletedAt: item?.deletedAt
  }))(checklistFields);

  await fetch(`${backendUrl}/workflow/${id}`, {
    credentials: "include",
    method: "PATCH",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify({
      ...workflow,
      checklistFields: encodedFields
    })
  });
};

/**
 * Deletes workflow template
 *
 * @param {workflowId} request for deleting workflow template
 */
const deleteWorkflow = async (workflowId: number) => {
  await fetch(`${backendUrl}/workflow/${workflowId}`, {
    credentials: "include",
    method: "DELETE",
    headers: {
      "Content-type": "application/json"
    }
  });
};

/**
 * Get next sequence number for workflow
 *
 * @param {id} workflow id
 * @return {number} next sequence number
 */
const getNextSeqNo = async ({ id }: { id: number }) => {
  const response = await fetch(`${backendUrl}/workflow/${id}/next`, {
    credentials: "include",
    method: "GET"
  });

  return response.json();
};

/**
 * Set recent workflow
 *
 * @param {UID} uid of user
 * @param {number} workflow id of workflow
 * @returns {Number} Id of the workflow set
 */
const setRecentWorkflow = async ({
  uid,
  workflows
}: {
  uid: UID,
  workflows: Array<number>
}) => {
  const workflow = await connection()
    .collection(`userData/${uid}/appData`)
    .doc("workflows");

  workflow.set({ workflows });

  return workflow.id;
};

/**
 * Get recent workflows
 *
 * @returns {Array<Number>} List of recently used workflows
 */
const getRecentWorkflow = async ({ uid }: { uid: UID }) => {
  const workflow = await connection()
    .collection(`userData/${uid}/appData`)
    .doc("workflows")
    .get();

  const recent = workflow.data();
  return recent;
};

/**
 * Get Process instances for given process templates
 *
 * @param  {workflow} workflow
 * @return {Array} return array of UnifizeChatRoom
 */
const getProcessInstances = async ({
  id,
  signal
}: {
  id: number,
  signal: AbortSignal
}) => {
  const url = `${backendUrl}/workflow/${id}/chatroom`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    },
    signal
  });

  return response.json();
};

/**
 * Get process instances for given process template
 *
 * @param  {workflow} workflow
 * @return {Array} return array of UnifizeChatRoom
 */
export const getProcessInstancesAsync = async ({
  id,
  signal
}: {
  id: number,
  signal: AbortSignal
}) => {
  const url = `${backendUrl}/workflow-async/${id}/chatroom?expand=groups`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    },
    signal
  });

  return response.json();
};

/**
 * Get Uniq value Process instance field
 *
 * @param  {workflow}
 * @return {Array} return array of ids (of uniq values)
 */
const getUniqProcessInstanceValues = async ({
  workflow
}: {
  workflow: number
}) => {
  const url = `${backendUrl}/workflow/${workflow}/chatroom/unique`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET"
  });
  return response.json();
};

/**
 * Get download process instance
 *
 * @param  {workflow}
 * @return {Array} return array of ids (of uniq values)
 */
const downloadProcessInstance = async ({
  filename,
  workflow,
  filter
}: {
  filename: string,
  workflow: number,
  filter: string
}) => {
  const response = await fetch(
    `${backendUrl}/workflow/${workflow}/chatroom/download?${filter}`
  );

  if (response.status === 202) {
    toast.success(
      "A download link will be emailed to you in a couple of minutes."
    );
  } else {
    const blob = await response.blob();
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.setAttribute("download", filename);
    a.click();
  }
};

/**
 * Download process instance with files
 *
 * @param {number} id - current workflow id
 * @param {("json" | "files")} format - type of export to run
 * @param {boolean} includeFiles (Optional) - include files in the export
 */
const initiateExportJob = async (
  id: number,
  format: "json" | "xlsx",
  includeFiles: ?boolean
) =>
  easyFetch.post("/export/job", {
    body: {
      templateId: id,
      format,
      ...(includeFiles ? { includeFiles } : {})
    }
  });

/**
 * Get Principal checklist
 *
 * @param  {workflow} Workflow template id
 * @return {Object} Checklist with its fields
 */
const getPrincipalChecklist = async ({ workflow }: { workflow: number }) => {
  const url = `${backendUrl}/workflow/${workflow}/checklist`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  const checklist = await response.json();

  if (!R.isEmpty(checklist)) {
    const reader = transit.reader("json", { handlers: fieldReader });
    const decodedFields = R.map(
      field => ({ ...reader.read(field.attrs), id: field.id }),
      checklist.fields || []
    );

    return {
      creator: checklist.creator,
      title: checklist.title,
      fields: decodedFields
    };
  }

  return checklist;
};

/**
 * Save custom view
 *
 * @param {number} orgId template id
 * @param {Array} columns to be saved
 * @param {UID} uid of user
 * @param {number} templateId of the template
 *
 */
const saveCustomizedView = async ({
  orgId,
  columns,
  uid,
  templateId
}: {
  orgId: number,
  uid: UID,
  columns: Array<string>,
  templateId: number
}) => {
  const customView = connection()
    .collection(
      `userData/${uid}/appData/settings/${orgId}/processTable/columns`
    )
    .doc(`${templateId}`);

  await customView.set({ columns });

  return columns;
};

/**
 * Bulk update process
 * @param {BulkUpdateProcessAPIPayload} options attributes to bulk update
 *
 */
const bulkUpdate = (options: BulkUpdateProcessAPIPayload) => {
  const url = `${backendUrl}/chatrooms`;
  return fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(options)
  });
};

export const fetchStatusCount = async (id: number) => {
  const url = `${backendUrl}/workflow/${id}/status`;
  const response = await fetch(url, {
    credentials: "include",
    method: "GET",
    headers: {
      "Content-type": "application/json"
    }
  });

  return response.json();
};

/**
 * Get process field mappings for imports
 * @param {number} templateId - process template id
 * @param {string} name - file name from s3
 */

export const getProcessFieldMappings = ({
  templateId,
  fileName
}: {
  templateId: ProcessTemplateId,
  fileName: string
}) =>
  easyFetch.get(`/import/field-mappings/${templateId}?fileName=${fileName}`);

export const storeProcessFieldMappings = ({
  templateId,
  fileName,
  localMappedField,
  file
}: {
  templateId: ProcessTemplateId,
  fileName: string,
  localMappedField: FieldMappings,
  file: CSVFileDetails
}) =>
  easyFetch.patch(`/import/field-mappings/${templateId}?fileName=${fileName}`, {
    body: {
      fieldMappings: localMappedField,
      file
    }
  });

/**
 *  Get the field details for the linked fields
 *  @param {number} templateId - ID of the process template
 */
const getEmbeddedFieldDetails = (templateId: ProcessTemplateId) =>
  easyFetch.get(`/xtdb/workflow/${templateId}/checklist`);

export {
  bulkUpdate,
  getWorkflow,
  createWorkflow,
  editWorkflow,
  deleteWorkflow,
  getNextSeqNo,
  setRecentWorkflow,
  getRecentWorkflow,
  getProcessInstances,
  getUniqProcessInstanceValues,
  downloadProcessInstance,
  initiateExportJob,
  getPrincipalChecklist,
  saveCustomizedView,
  getEmbeddedFieldDetails
};
