// @flow

import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { Set as ImmutableSet, Map as ImmutableMap } from "immutable";
import * as R from "ramda";
import type { Set as ImmutableSetType } from "immutable";

import type {
  Action,
  WorkflowState,
  WorkflowById,
  Workflow,
  WorkflowId,
  WorkflowInstanceFilter,
  UniqueInstanceValues,
  WorkflowBuilderDialog,
  PrincipalChecklist,
  ProcessInstanceColumn,
  NestedRows,
  StatusCount,
  StatusRemoveWarning,
  UID,
  ManageViewShowVersions,
  FormFieldsVisibility,
  FieldId,
  InstancesById,
  ExpandedFields,
  FormsById,
  WorkflowInstances,
  CSVFieldMappings,
  ProcessTemplateId,
  AllRecords
} from "src/types";
import * as atypes from "src/constants/actionTypes";
import options from "src/constants/processInstanceColumns";
import { mergeRevisionsById } from "src/utils";
import { dataStages } from "../constants";

const recent = (
  state: ImmutableSetType<number> = new ImmutableSet(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SET_RECENT_WORKFLOW_SUCCESS:
      return payload ? ImmutableSet(R.take(4, payload.workflows)) : [];
    case atypes.GET_RECENT_WORKFLOW_SUCCESS:
      return payload ? ImmutableSet(payload.workflows) : [];
    default:
      return state;
  }
};

const byId = (state: WorkflowById = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.SYNC_WORKFLOWS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(R.map(w => ({ [w.id]: w }), payload.workflows))
      };
    case atypes.DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        [payload.workflow]: {
          ...state[payload.workflow],
          deleted: true
        }
      };
    default:
      return state;
  }
};

const allIds = (
  state: ImmutableSetType<number> = ImmutableSet(),
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SYNC_WORKFLOWS_SUCCESS:
      return state.union(R.map(R.prop("id"), payload.workflows));
    default:
      return state;
  }
};

const nextSeqNo = (state: ?number = null, { type, payload }: Action) => {
  switch (type) {
    case atypes.GET_NEXT_SEQ_NO_REQUEST_SUCCESS:
      return payload.seqNo ? parseInt(payload.seqNo, 10) : state;
    case atypes.CLEAR_NEXT_SEQ_NO:
    case atypes.CREATE_CHATROOM_SUCCESS:
    case atypes.CLEAR_NEW_CONVERSATION:
      return null;
    default:
      return state;
  }
};

const instancesById = (
  state: InstancesById = {},
  { type, payload, meta }: Action
) => {
  switch (type) {
    case atypes.GET_WORKFLOW_INSTANCES_SUCCESS:
    case atypes.SET_UPDATED_INSTANCES: {
      return mergeRevisionsById({ items: payload.workflows });
    }
    case atypes.SET_UPDATED_INSTANCES_BY_ID:
      return payload;
    case atypes.CLEAR_WORKFLOW_INSTANCES:
      return {};
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_REPORTS_REQUEST:
      const filter = (meta || {}).query || {};
      const page = parseInt(filter.page || 0, 10);
      if (page > 1) {
        return state;
      }
      return {};
    case atypes.UPDATE_WORKFLOW_INSTANCE_CURRENT_VERSION_SUCCESS:
      return mergeRevisionsById({ items: payload.workflows });
    case atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC:
    case atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC:
    case atypes.UPDATE_CHATROOM_FROM_MANAGE_VIEW_FAILURE:
    case atypes.UPDATE_CHECKLIST_FROM_MANAGE_VIEW_FAILURE:
      return {
        ...state,
        [payload.autoNo]: {
          ...state[payload.autoNo],
          ...payload.values
        }
      };
    case atypes.UPDATE_EMBEDDED_CHECKLIST_FROM_MANAGE_VIEW_OPTIMISTIC:
      let updated = {
        ...state
      };
      const embeddedValue = Array.isArray(payload.value)
        ? payload.value
        : [payload.value];
      // update embedded fields in each instance
      payload.filteredInstances.forEach(instance => {
        const embeddedIndex = R.indexOf(
          payload.roomId,
          instance[payload.linkedFieldId].result
        );
        updated[instance["autoNo"]] = {
          ...instance,
          [payload.linkedFieldId]: {
            ...instance[payload.linkedFieldId],
            entities: {
              chatrooms: {
                ...instance[payload.linkedFieldId].entities.chatrooms,
                [payload.roomId]: {
                  ...instance[payload.linkedFieldId].entities.chatrooms[
                    payload.roomId
                  ],
                  chatroom: {
                    ...instance[payload.linkedFieldId].entities.chatrooms[
                      payload.roomId
                    ].chatroom,
                    [payload.fieldId]: payload.value
                  }
                }
              }
            }
          },
          [payload.columnId]: [
            ...(instance[payload.columnId]
              ? instance[payload.columnId].slice(0, embeddedIndex)
              : []),
            embeddedValue,
            ...(instance[payload.columnId]
              ? instance[payload.columnId].slice(embeddedIndex + 1)
              : [])
          ]
        };
      });
      return updated;
    case atypes.UPDATE_CHATROOM_FROM_CONVERSATION_MODAL:
      return {
        ...state,
        [payload.autoNo]: {
          ...(state[payload.autoNo] || {}),
          ...payload.values
        }
      };
    case atypes.BULK_UPDATE_PROCESS_OPTIMISTIC: {
      const rooms = payload.autoNos.map(autoNo => {
        return {
          ...(state[autoNo] || {}),
          ...payload.value
        };
      });
      return {
        ...state,
        ...mergeRevisionsById({ items: rooms })
      };
    }
    case atypes.BULK_UPDATE_PROCESS_FAILURE: {
      const rooms = payload.autoNos.map(autoNo => {
        return {
          ...(state[autoNo] || {}),
          ...payload.oldValues
        };
      });
      return {
        ...state,
        ...mergeRevisionsById({ items: rooms })
      };
    }

    case atypes.LOAD_CHATROOM_SUCCESS: {
      const { room } = payload;

      const instanceEntries = Object.entries(state);
      const instance = instanceEntries.find(item => {
        // $FlowFixMe
        const entry: WorkflowInstances = item[1];

        return entry.id === room.id;
      });

      if (!instance) return state;

      const instanceId = instance[0];

      return R.mergeDeepRight(state, {
        [instanceId]: room
      });
    }

    case atypes.FILTER_EMBEDDED_FIELDS: {
      const { instances } = payload;
      return instances;
    }

    default:
      return state;
  }
};

const showVersions = (
  state: ManageViewShowVersions = ImmutableMap(),
  { type, payload, meta }: Action
) => {
  switch (type) {
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_REPORTS_REQUEST:
      const filter = (meta || {}).query || {};
      const page = parseInt(filter.page || 0, 10);
      if (page > 1) {
        return state;
      }
      return ImmutableMap();

    case atypes.GET_WORKFLOW_INSTANCES_SUCCESS: {
      let newState = state;
      payload.workflows.forEach(workflow => {
        if (!newState.has(`${workflow.seqNo}`))
          newState = newState.set(`${workflow.seqNo}`, false);
      });
      return newState;
    }

    case atypes.TOGGLE_PROCESS_VERSIONS_VISIBILITY:
      return state.set(payload.seqNo, !state.get(payload.seqNo));

    default:
      return state;
  }
};

export const getShowVersionInfo = (state: WorkflowState) => state.showVersions;

const loadingInstances = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.GET_WORKFLOW_INSTANCES_SUCCESS:
    case atypes.PAGINATE_WORKFLOW_INSTANCES_SUCCESS:
    case atypes.GET_WORKFLOW_INSTANCES_FAILURE:
      return false;
    case atypes.SET_REPORTS_SUCCESS:
    case atypes.SET_PROCESS_SUCCESS:
      return true;
    default:
      return state;
  }
};

const searchResults = (state: Array<number> = [], { type, payload }) => {
  switch (type) {
    case atypes.SEARCH_WORKFLOW_SUCCESS:
      return payload.result;
    case atypes.RESET_WORKFLOW_SEARCH_SUCCESS:
      return payload.workflows;
    default:
      return state;
  }
};

const lastCreated = (state: ?number = null, { type, payload }) => {
  switch (type) {
    case atypes.CREATE_WORKFLOW_SUCCESS:
      return payload.id;
    case atypes.CLEAR_LAST_CREATED_WORKFLOW:
      return null;
    default:
      return state;
  }
};

/**
 * Converts all the filterable fields value's to array
 */
const convertFilterValuesToArr = (filter: Object) => {
  const multiSelections = R.difference(R.keys(filter), [
    "id",
    "title",
    "page",
    "reportId",
    "chartId",
    "deepFilter"
  ]);

  const multipleValues = R.mergeAll(
    R.map(
      key => ({
        [key]: R.type(filter[key]) === "Array" ? filter[key] : [filter[key]]
      }),
      multiSelections
    )
  );

  return { ...filter, ...multipleValues };
};

const instanceFilter = (
  state: WorkflowInstanceFilter = {},
  { type, payload, meta }
) => {
  switch (type) {
    case atypes.RESET_WORKFLOW_INSTANCE_FILTER:
      return {};
    case atypes.UPDATE_INSTANCE_FILTER:
      return {
        ...state,
        ...convertFilterValuesToArr(payload)
      };
    case atypes.SET_FORMS_REQUEST:
    case atypes.SET_INSTANCE_FILTER: {
      const filter = (meta || {}).query || payload;

      // Retain search filter
      return {
        ...R.pick(["title"], state),
        ...convertFilterValuesToArr(filter),
        page: parseInt(filter["page"] || 1, 10)
      };
    }
    case atypes.SET_PROCESS_REQUEST: {
      const filter = (meta || {}).query || payload;
      return {
        ...convertFilterValuesToArr(filter),
        page: parseInt(filter["page"] || 1, 10)
      };
    }

    case atypes.SET_FORMS_SUCCESS:
    case atypes.SET_REPORTS_SUCCESS: {
      const filter = payload || {};
      return {
        ...convertFilterValuesToArr(filter),
        page: parseInt(filter["page"] || 1, 10)
      };
    }
    case atypes.SET_REPORTS_REQUEST:
      if (payload.id) {
        return { ...state, reportId: payload.id };
      }
      return state;

    default:
      return state;
  }
};

const allRecords = (state: AllRecords = {}, { type, payload }) => {
  switch (type) {
    case atypes.TOGGLE_ALL_RECORDS_FILTER:
      if (payload.columnId) {
        return {
          ...state,
          [payload.columnId]: !state[payload.columnId]
        };
      }
      return state;
    case atypes.RESET_WORKFLOW_INSTANCE_FILTER:
      return {};
    case atypes.SET_ALL_RECORDS_FILTER:
      if (payload.columnId) {
        return {
          ...state,
          [payload.columnId]: payload.value
        };
      }
      return state;
    case atypes.SET_REPORTS_SUCCESS:
      const deepFilter = {};

      (payload.deepFilter || []).map(filterColumnId => {
        deepFilter[filterColumnId] = true;
      });

      return {
        ...state,
        ...deepFilter
      };

    case atypes.GET_REPORTS_SUCCESS:
      const updatedDeepFilter = {};

      (payload[0]?.filter?.deepFilter || []).map(filterColumnId => {
        deepFilter[filterColumnId] = true;
      });

      return {
        ...state,
        ...updatedDeepFilter
      };

    case atypes.CLEAR_ALL_RECORDS_FILTER:
      return {};
    default:
      return state;
  }
};

const uniqueValues = (state: UniqueInstanceValues = {}, { type, payload }) => {
  switch (type) {
    case atypes.GET_FORM_UNIQUE_INSTANCE_VALUES_SUCCESS:
    case atypes.GET_PROCESS_UNIQUE_INSTANCE_VALUES_SUCCESS:
      return payload.uniqueValues;
    case atypes.SET_FIELD_UNIQUE_VALUES:
      return { ...state, [payload.id]: payload.value };
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return {};
    default:
      return state;
  }
};

const uniqueSearchValues = (
  state: UniqueInstanceValues = {},
  { type, payload }
) => {
  switch (type) {
    case atypes.GET_PROCESS_UNIQUE_INSTANCE_VALUES_SUCCESS:
      return payload.uniqueValues;
    case atypes.SET_CURRENT_CHATROOM_REQUEST:
      return {};
    case atypes.SEARCH_UNIQUE_CONVERSATION_SUCCESS:
    case atypes.SEARCH_UNIQUE_WORKFLOW_SUCCESS:
      return { ...(state || {}), [payload.column]: payload.result };
    case atypes.SEARCH_UNIQUE_CREATOR_SUCCESS:
      return { ...(state || {}), creator: payload.result };
    case atypes.SEARCH_UNIQUE_OWNER_SUCCESS:
      return { ...(state || {}), owner: payload.result };
    case atypes.SEARCH_UNIQUE_PARTICIPANT_SUCCESS:
      return { ...(state || {}), members: payload.result };
    case atypes.SEARCH_UNIQUE_COMPLETED_BY_SUCCESS:
      return { ...(state || {}), completedBy: payload.result };
    case atypes.SEARCH_USERS_SUCCESS:
      return { ...(state || {}), [payload.columnId]: payload.result };
    default:
      return state;
  }
};

const paginateInstance = (state: boolean = true, { type, payload }) => {
  switch (type) {
    case atypes.GET_WORKFLOW_INSTANCES_SUCCESS:
    case atypes.PAGINATE_WORKFLOW_INSTANCES_SUCCESS:
      return !R.isEmpty(payload.workflows);
    default:
      return state;
  }
};

const intialWorkflowDialog = {
  advanced: false,
  header: "Define New Process Template",
  id: null,
  title: "",
  description: "",
  data: false,
  members: [],
  groups: [],
  processOwners: [],
  owner: null,
  numberingScheme: "",
  checklists: [],
  notificationGroups: [],
  edit: false,
  files: [],
  show: false,
  checklistFieldSettings: {
    label: "",
    tab: "",
    type: "",
    position: 0
  },
  error: null,
  loading: dataStages.idle,
  privacy: "none",
  status: [
    { id: -1, active: true },
    { id: -2, active: false }
  ],
  privacySettings: {
    whitelist: []
  },
  settings: {
    nextCount: null,
    hideAutoNumber: false,
    statusDisabled: false,
    creatorIsOwner: true,
    creatorIsParticipant: true,
    senderNotParticipant: false,
    noConfirmation: false,
    dueIn: null,
    allowArchive: true,
    isProcessTablePaginated: false,
    isReportPaginated: false
  },
  reminder: [],
  builderComplete: false,
  layout: {
    checklistOpenState: {
      web: true,
      lite: true,
      liteMobile: false
    },
    checklistWidth: "1"
  }
};

const builderDialog = (
  state: WorkflowBuilderDialog = intialWorkflowDialog,
  { type, payload }
) => {
  switch (type) {
    case atypes.SET_WORKFLOW_BUILDER_ATTRIBUTE:
      return { ...state, [payload.id]: payload.value };

    case atypes.CREATE_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: dataStages.updated,
        error: "Error Creating Process"
      };

    case atypes.EDIT_WORKFLOW_FAILURE:
      return {
        ...state,
        loading: dataStages.updated,
        error: "Error Editing Process"
      };

    case atypes.CREATE_WORKFLOW_REQUEST:
    case atypes.EDIT_WORKFLOW:
      return { ...state, loading: dataStages.updating, error: null };

    case atypes.SET_WORKFLOW_BUILDER_ATTRIBUTES:
      return { ...state, ...payload.value };

    case atypes.SET_WORKFLOW_BUILDER_REMINDER:
      return {
        ...state,
        reminder: (state.reminder || []).map((value: Object, index: number) => {
          if (index === payload.index) {
            return { ...value, ...payload.value };
          }
          return value;
        })
      };

    case atypes.REMOVE_WORKFLOW_BUILDER_REMINDER:
      return {
        ...state,
        reminder: (state.reminder || []).filter(
          (value: Object, index: number) => index !== payload.index
        )
      };

    case atypes.EDIT_WORKFLOW_SUCCESS:
    case atypes.UPDATE_WORKFLOW_BUILDER:
      return {
        ...state,
        loading: dataStages.updated
      };

    case atypes.CREATE_WORKFLOW_SUCCESS:
    case atypes.HIDE_WORKFLOW_BUILDER:
      return intialWorkflowDialog;

    default:
      return state;
  }
};

const initialPrincipalChecklist = {
  creator: null,
  title: null,
  fields: []
};

const principalChecklist = (
  state: PrincipalChecklist = initialPrincipalChecklist,
  { type, payload, meta }: Action
) => {
  switch (type) {
    case atypes.GET_PRINCIPAL_CHECKLIST_SUCCESS:
      return payload.checklist;
    case atypes.SET_PROCESS_REQUEST:
      if (
        !R.isEmpty(R.omit(["page", "asc", "desc"], (meta || {}).query || {}))
      ) {
        return state;
      }
      return initialPrincipalChecklist;
    case atypes.SET_REPORTS_REQUEST:
      return initialPrincipalChecklist;
    default:
      return state;
  }
};

const principalChecklistSearchResult = (
  state: Array<number> = [],
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SEARCH_PRINCIPAL_CHECKLIST_SUCCESS:
      return payload.result;
    default:
      return state;
  }
};

const instanceCount = (
  state: string = "Loading",
  { type, meta, payload }: Action
) => {
  switch (type) {
    case atypes.GET_PROCESS_INSTANCE_COUNT_SUCCESS:
      return payload.totalCount;
    case atypes.SET_PROCESS_REQUEST:
    case atypes.SET_REPORTS_REQUEST:
      const filter = (meta || {}).query || {};
      const page = parseInt(filter.page || 0, 10);
      if (page > 1 && state !== "Loading") {
        return state;
      }
      return "Loading";

    default:
      return state;
  }
};

const processInstanceColumn = (
  state: ProcessInstanceColumn = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.REORDER_PROCESS_COLUMN:
      if (!payload.reportId) {
        return { ...state, [payload.id]: payload.columns };
      }
      return state;
    case atypes.SYNC_WORKFLOWS_SUCCESS:
      return {
        ...state,
        ...R.mergeAll(
          R.map(w => {
            if (state[`${w.id}`]) {
              return state;
            }
            return { [w.id]: options };
          }, payload.workflows)
        )
      };
    case atypes.SYNC_CUSTOM_PROCESS_COLUMN_SUCCESS:
      return {
        ...state,
        ...payload.processColumn
      };
    default:
      return state;
  }
};

const selectedRows = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.TOGGLE_PROCESS_ROW_SELECTION:
      return R.includes(payload.row, state)
        ? R.reject(R.equals(payload.row), state)
        : [...state, payload.row];
    case atypes.CLEAR_PROCESS_ROW_SELECTION:
      return [];
    default:
      return state;
  }
};

const mostUsed = (state: Array<number> = [], { type, payload }: Action) => {
  switch (type) {
    case atypes.MOST_USED_WORKFLOW:
      return payload.workflows;
    default:
      return state;
  }
};

const nestedRows = (state: NestedRows = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.FETCH_NESTED_ROWS_SUCCESS:
    case atypes.UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_OPTIMISTIC:
    case atypes.UPDATE_NESTED_CHATROOM_FROM_MANAGE_VIEW_FAILURE:
      return { ...state, [payload.parentId]: payload.nestedRows };
    default:
      return state;
  }
};

const statusCount = (state: StatusCount = {}, { type, payload }: Action) => {
  switch (type) {
    case atypes.FETCH_STATUS_COUNT_SUCCESS:
      return payload.statusCount;
    case atypes.FETCH_STATUS_COUNT_REQUEST:
    case atypes.CREATE_WORKFLOW_SUCCESS:
    case atypes.EDIT_WORKFLOW_SUCCESS:
    case atypes.HIDE_WORKFLOW_BUILDER:
      return {};
    default:
      return state;
  }
};

const initialStatusRemoveWarning = {
  show: false,
  id: null,
  count: null,
  workflow: null
};

const statusRemoveWarning = (
  state: StatusRemoveWarning = initialStatusRemoveWarning,
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.SHOW_STATUS_REMOVE_WARNING:
      return { ...state, ...payload, show: true };
    case atypes.FETCH_STATUS_COUNT_REQUEST:
      return { ...state, workflow: payload.id };
    case atypes.HIDE_STATUS_REMOVE_WARNING:
      return { ...state, show: false };
    case atypes.HIDE_WORKFLOW_BUILDER:
      return initialStatusRemoveWarning;
    default:
      return state;
  }
};

const additionalFilters = (state = {}, { type, payload, meta }: Action) => {
  switch (type) {
    case atypes.SET_REPORTS_REQUEST: {
      const filter = (meta || {}).query || {};
      // When user refreshes the browser if there is multiple values browser returns array
      // but returns string for single values so it needs to be converted to array
      const multiSelections = R.difference(R.keys(filter), [
        "id",
        "title",
        "page",
        "reportId",
        "chartId"
      ]);

      const multipleValues = R.mergeAll(
        R.map(
          key => ({
            [key]: R.type(filter[key]) === "Array" ? filter[key] : [filter[key]]
          }),
          multiSelections
        )
      );
      const newFilter = { ...filter, ...multipleValues };
      return { ...newFilter, page: parseInt(newFilter["page"] || 1, 10) };
    }
    case atypes.SET_PROCESS_REQUEST:
      return {};
    case atypes.UPDATE_INSTANCE_FILTER: {
      if (!R.isEmpty(payload.chartId)) {
        return { ...state, chartId: payload.chartId };
      }
      return state;
    }
    default:
      return state;
  }
};

/**
 * Tracks which forms fields are expanded in the manage view
 */
const formFieldsVisibility = (
  state: FormFieldsVisibility = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.TOGGLE_FORM_FIELDS_VISIBILITY:
      return {
        ...state,
        [payload.fieldId]: {
          ...(state[payload.fieldId] || {}),
          [payload.formId]: !state[payload.fieldId]?.[payload.formId]
        }
      };
    case atypes.SET_FORM_FIELDS_VISIBILITY:
      return {
        ...state,
        [payload.fieldId]: {
          ...(state[payload.fieldId] || {}),
          [payload.formId]: payload.value
        }
      };
    case atypes.SET_PROCESS_SUCCESS:
      return {};

    case atypes.SET_REPORT_FORM_VISIBILITY:
      return {
        ...state,
        ...payload
      };
    case atypes.CREATE_REPORT_SUCCESS:
      const reportId = Object.keys(payload)[0];
      const report = payload[reportId];
      return report.settings?.expansionState?.formFieldsVisibility || state;
    case atypes.EDIT_REPORT_SUCCESS:
      return payload.settings?.expansionState?.formFieldsVisibility || state;
    default:
      return state;
  }
};

const expandedFields = (
  state: ExpandedFields = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.RESET_WORKFLOW_INSTANCE_FILTER:
      return {};
    case atypes.SET_EXPANDED_FIELD:
      return {
        ...state,
        [payload.autoNo]: {
          ...(state[payload.autoNo] || {}),
          [payload.fieldId]: payload.value
        }
      };
    default:
      return state;
  }
};

const csvFieldMappings = (
  state: CSVFieldMappings = {},
  { type, payload }: Action
) => {
  switch (type) {
    case atypes.UPLOAD_CSV_TO_PROCESS_REQUEST:
    case atypes.GET_PROCESS_FIELD_MAPPINGS_REQUEST:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.fetching
        }
      };

    case atypes.UPLOAD_CSV_TO_PROCESS_SUCCESS:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.fetched,
          file: payload.file
        }
      };

    case atypes.UPLOAD_CSV_TO_PROCESS_FAILURE:
    case atypes.CLEAR_CSV_UPLOAD_DATA_STAGE:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.idle
        }
      };
    case atypes.GET_PROCESS_FIELD_MAPPINGS_FAILURE:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.fetched
        }
      };

    case atypes.GET_PROCESS_FIELD_MAPPINGS_SUCCESS:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.fetched,
          mappedFields: payload.mappedFields
        }
      };

    case atypes.OPEN_CSV_UPLOAD_MODAL:
      return state[payload.templateId]
        ? state
        : { ...state, [payload.templateId]: { loading: dataStages.idle } };

    case atypes.STORE_PROCESS_FIELD_MAPPINGS_REQUEST:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.updating
        }
      };

    case atypes.STORE_PROCESS_FIELD_MAPPINGS_SUCCESS:
    case atypes.STORE_PROCESS_FIELD_MAPPINGS_FAILURE:
      return {
        ...state,
        [payload.templateId]: {
          ...state[payload.templateId],
          loading: dataStages.updated
        }
      };

    default:
      return state;
  }
};

// Check if the instances are formatted or not
// If SET_UPDATED_INSTANCES action is dispatched
// then it is formatted
const areInstancesFormatted = (state: boolean = false, { type }: Action) => {
  switch (type) {
    case atypes.SET_UPDATED_INSTANCES:
      return true;
    case atypes.SET_REPORTS_REQUEST:
      return false;
    default:
      return state;
  }
};

const workflow = combineReducers<Object, Action>({
  additionalFilters,
  statusRemoveWarning,
  statusCount,
  nestedRows,
  byId,
  allIds,
  instancesById,
  showVersions,
  nextSeqNo,
  recent,
  searchResults,
  lastCreated,
  instanceFilter,
  uniqueValues,
  uniqueSearchValues,
  paginateInstance,
  loadingInstances,
  builderDialog,
  principalChecklist,
  principalChecklistSearchResult,
  instanceCount,
  processInstanceColumn,
  selectedRows,
  mostUsed,
  formFieldsVisibility,
  expandedFields,
  csvFieldMappings,
  allRecords,
  areInstancesFormatted
});

export default workflow;

export const getNestedRows = (state: WorkflowState, roomId: string) =>
  state.nestedRows[`${roomId}`];

export const selectWorkflow = (state: WorkflowState, id: WorkflowId) =>
  state.byId[`${id}`] || {};

export const getWorkflow = createSelector(selectWorkflow, workflow => workflow);

export const selectWorkflowSelectedRows = (state: WorkflowState) =>
  state.selectedRows;

export const getWorkflowSelectedRows = createSelector(
  selectWorkflowSelectedRows,
  selectedRows => selectedRows
);

const getProcessInstanceColumn = (state: WorkflowState) =>
  state.processInstanceColumn;
const getWorkflowId = (state: WorkflowState, id: WorkflowId) => id;

export const getProcessTableColumns = createSelector(
  [getProcessInstanceColumn, getWorkflowId],
  (processInstanceColumn, id) => processInstanceColumn[id] || options
);

export const getProcessFilterId = (state: WorkflowState) =>
  state.instanceFilter.id;

export const getProcessFilterPage = (state: WorkflowState) => {
  if (state.additionalFilters.page) {
    return state.additionalFilters.page || 1;
  }

  return state.instanceFilter.page || 1;
};

const getAllIds = state => state.allIds;
export const getWorkflowsById = (state: WorkflowState) => state.byId;

export const isProcessRowSelected = (state: WorkflowState, id: string) =>
  R.includes(id, state.selectedRows);

export const getWorkflowTemplates = createSelector(
  [getAllIds, getWorkflowsById],
  (ids, workflows) =>
    R.sortWith([R.ascend(R.prop("title"))])(
      ids
        .filter(id => !workflows[id].deleted)
        .map(i => workflows[i])
        .toArray() || []
    )
);

export const getWorkflowIdsForNew = createSelector(
  [getAllIds, getWorkflowsById],
  (ids, workflows) => {
    const idsExcludingHiddenWorkflows = R.filter(
      id =>
        !workflows[id]?.settings?.hideProcessInNew && !workflows[id]?.deleted,
      ids
    );
    return R.sortWith([R.ascend(R.prop("title"))])(
      idsExcludingHiddenWorkflows.map(i => workflows[i]).toArray() || []
    );
  }
);

export const getWorkflowColor = (state: WorkflowState, id: WorkflowId) => {
  return state.byId[id]?.color;
};

export const getWorkflowName = (state: WorkflowState, id: WorkflowId) =>
  state.byId[id] ? state.byId[id].title : "";

export const getRecentWorkflows = (state: WorkflowState): Array<number> =>
  state.recent.toArray();

export const getChatroomHideStatus = (state: WorkflowState, id: string) => {
  if (state.byId && state.byId[id]) {
    return state.byId[id].settings
      ? state.byId[id].settings.statusDisabled
      : false;
  }
  return false;
};

export const getWorkflowTitle = createSelector(
  getWorkflow,
  (workflow: Workflow) => {
    if (workflow) {
      return workflow.title;
    }
    return "";
  }
);

export const getWorkflowEditable = createSelector(
  getWorkflow,
  (workflow: Workflow) => {
    if (workflow) {
      return workflow?.settings?.editable;
    }
    return true;
  }
);

export const getUniqueValues = (state: WorkflowState, column: string) =>
  state.uniqueValues[column] || [];

const getAllUniqueSearchValues = (state: WorkflowState) =>
  state.uniqueSearchValues;
const getAllUniqueValues = (state: WorkflowState) => state.uniqueValues;
const getWorkflowColumn = (state: WorkflowState, column: string) => column;

export const getSearchUniqueValues = createSelector(
  [getAllUniqueSearchValues, getAllUniqueValues, getWorkflowColumn],
  (uniqueSearchValues, uniqueValues, column) =>
    uniqueSearchValues[column] || uniqueValues[column] || []
);

export const hasUniqueValue = (state: WorkflowState, column: string) =>
  (state.uniqueValues[column] || []).length !== 0;

export const getInstanceFilter = (state: WorkflowState) => state.instanceFilter;

export const getInstanceFilterId = createSelector(
  getInstanceFilter,
  filter => filter.id
);

export const getProcessChartId = (state: WorkflowState) =>
  state.instanceFilter.chartId;

export const getInstanceReportId = (state: WorkflowState) =>
  state.instanceFilter.reportId;

export const getWorkflowInstanceAscending = (state: WorkflowState) => {
  const workflowInstances: Function =
    R.head(state.instanceFilter.sort || []) || "";
  return R.includes("asc", workflowInstances);
};

export const getWorkflowInstancesort = (state: WorkflowState) => {
  const getSort: Function = (sort: string) =>
    (sort || "").replace(":asc", "").replace(":desc", "");
  // $FlowFixMe
  return R.map(getSort, state.instanceFilter.sort || []);
};

export const getPrincipalChecklist = (state: WorkflowState) =>
  state.principalChecklist;

export const getPrincipalChecklistFields = createSelector(
  [getPrincipalChecklist],
  principalChecklist =>
    // $FlowFixMe
    R.mergeAll(
      R.map((f: Object) => ({ [f.id]: f.label }), principalChecklist.fields) ||
        {}
    )
);

export const getPrincipalChecklistFieldsList = (state: WorkflowState) =>
  // $FlowFixMe
  R.map(
    (f: Object) => ({
      id: f.id,
      label: f.label,
      type: f.type,
      settings: JSON.parse(f.settings)
    }),
    state.principalChecklist.fields
  );

/**
 * Gets the current checklist forms and their associated form fields.
 *
 * @param {WorkflowState} state - The current workflow state.
 * @param {FormsById} formsById - An object mapping form IDs to form objects.
 * @returns {FormField[]} An array of objects representing form-field pairs.
 */
export const getCurrentChecklistFormWithFields = (
  state: WorkflowState,
  formsById: FormsById
) => {
  const formFields = R.filter(
    field => field.type === "form",
    state.principalChecklist.fields
  );

  try {
    return formFields.reduce((acc, formField) => {
      const selectedForms =
        JSON.parse(formField.settings || "{}").selectedForms || [];

      return [
        ...acc,
        ...selectedForms.map(form => ({
          form,
          field: formField.id,
          label: `${formsById[form].title} ${formField.label}`
        }))
      ];
    }, []);
  } catch {
    return [];
  }
};

export const getCurrentChecklistFormDetails = (
  state: WorkflowState,
  formsById: FormsById
) => {
  const formFields = R.filter(
    field => field.type === "form",
    state.principalChecklist.fields
  );

  try {
    return formFields.reduce((acc, formField) => {
      const selectedForms =
        JSON.parse(formField.settings || "{}").selectedForms || [];

      return [
        ...acc,
        ...selectedForms.map(form => ({
          form,
          formTitle: `${formsById[form].title}`,
          checklistFormId: formField.id,
          checklistFormLabel: `${formField.label}`
        }))
      ];
    }, []);
  } catch {
    return [];
  }
};

export const getPrincipalChecklistFieldName = (
  state: WorkflowState,
  fieldId: string
) => {
  const field = R.head(
    R.filter(
      field => `${field.id}` === `${fieldId}`,
      state.principalChecklist.fields
    )
  );

  if (field) {
    return field.label;
  }
  return "";
};

export const getPrivacySettings = (state: WorkflowState) => {
  const { builderDialog: _builderDialog } = state;
  const privacySettings = _builderDialog.privacySettings || {};

  if (privacySettings.whitelist) {
    return privacySettings;
  }

  return {
    whitelist: []
  };
};

const getBuilderDialog = (state: WorkflowState) => state.builderDialog;

export const getCustomStatus = createSelector(
  [getWorkflow],
  (workflow: Workflow) => workflow.status || []
);

export const getWorkflowBuilderStatus = createSelector(
  getBuilderDialog,
  (builder: WorkflowBuilderDialog) => builder.status
);

export const getWorkflowBuilderChecklistIds = createSelector(
  getBuilderDialog,
  (builder: WorkflowBuilderDialog) => builder.checklists
);

export const getWorkflowCounts = createSelector(
  [getWorkflow],
  (workflow: Workflow) => workflow.invocationCount || 0
);

export const getWorkflowSettings = createSelector(
  [getWorkflow],
  (workflow: Workflow) => workflow.settings || {}
);

export const getWorkflowAdhocArchived = createSelector(
  [getWorkflowSettings],
  settings => settings.allowArchive
);

const getUniqueColumnNames = (state: WorkflowState) =>
  R.keys(state.uniqueValues).map(id => ({ id }));

const principalChecklistFieldTypes = (state: WorkflowState) =>
  R.mergeAll(
    R.map(
      (f: Object) => ({ [f.id]: f.type }),
      state.principalChecklist.fields
    ) || {}
  );

export const getPrincipalChecklistFieldNames = (state: WorkflowState) =>
  // $FlowFixMe
  R.mergeAll(
    R.map(
      (f: Object) => ({ [f.id]: f.label }),
      state.principalChecklist.fields
    ) || {}
  );

export const getSingleSelectColumnNames = createSelector(
  [getUniqueColumnNames, principalChecklistFieldTypes],
  (columns: Array<Object>, fieldsById: Object) => {
    return R.reject(
      field => {
        if (!isNaN(field.id)) {
          return R.includes(fieldsById[field.id], [
            "text",
            "number",
            "date",
            "pdf",
            "file"
          ]);
        }

        return false;
      },
      R.differenceWith((x, y) => x.id === y.id, columns || [], [
        { id: "title" },
        { id: "age" },
        { id: "members" },
        { id: "description" }
      ])
    );
  }
);

export const getFormFieldColumnNames = createSelector(
  [getUniqueColumnNames, principalChecklistFieldTypes],
  (columns: Array<Object>, fieldsById: Object) => {
    return R.filter(field => {
      if (!isNaN(field.id)) {
        return fieldsById[field.id] === "form";
      }

      return false;
    }, columns || []);
  }
);

export const getNumericalColumnNames = createSelector(
  [getUniqueColumnNames, principalChecklistFieldTypes],
  (columns: Array<Object>, fieldsById: Object) => {
    return R.filter(
      field => {
        if (!isNaN(field.id)) {
          return fieldsById[field.id] === "number";
        }

        return true;
      },
      R.differenceWith((x, y) => x.id === y.id, columns || [], [
        { id: "title" },
        { id: "creator" },
        { id: "status" },
        { id: "completedBy" },
        { id: "createdAt" },
        { id: "parent" },
        { id: "members" },
        { id: "owner" },
        { id: "priority" },
        { id: "description" }
      ])
    );
  }
);

export const getWorkflowInstanceFilter = (state: WorkflowState) =>
  state.instanceFilter;

export const isReportChanged = (state: WorkflowState) => {
  const changes = R.omit(["page", "chartId"], state.additionalFilters || {});
  return !R.isEmpty(changes);
};

export const getBuilderAutoReminder = (state: WorkflowState) =>
  state.builderDialog.reminder || [];

export const getWorkflowBuilderPendingStatus = (
  state: WorkflowState
): Array<Object> =>
  (state.builderDialog.status || []).filter(
    (status: Object): boolean => status.active !== false
  );

export const getWorkflowBuilderCompletedStatus = (
  state: WorkflowState
): Array<Object> =>
  (state.builderDialog.status || []).filter(
    (status: Object): boolean => status.active === false
  );

export const getCurrentWorkflowStatus = (state: WorkflowState) => {
  const id = getProcessFilterId(state);
  return getCustomStatus(state, id).map(R.prop("id"));
};

export const getPrincipalChecklistFieldSetting = (
  state: WorkflowState,
  fieldId: string
) => {
  const field = R.head(
    R.filter(
      field => `${field.id}` === `${fieldId}`,
      state.principalChecklist.fields
    )
  );

  if (field) {
    try {
      return JSON.parse(field.settings);
    } catch (error) {
      return {};
    }
  }
  return {};
};

export const getWorkflowWhitelistMembership = (
  state: WorkflowState,
  workflowId: WorkflowId,
  uid: UID
): boolean => {
  const workflow = getWorkflow(state, workflowId);

  // $FlowFixMe
  return workflow.privacySettings?.whitelist?.includes(uid);
};

export const getWorkflowPendingStatuses = createSelector(
  getWorkflow,
  (workflow: Workflow) => {
    if (workflow) {
      return R.reduce(
        (pendingStatuses, workflowStaus: Object) => {
          if (workflowStaus.active) {
            return [...pendingStatuses, workflowStaus.id];
          }
          return pendingStatuses;
        },
        [],
        workflow?.status || []
      );
    }
    return [];
  }
);

export const getWorkflowCompleteStatuses = createSelector(
  getWorkflow,
  (workflow: Workflow) => {
    if (workflow) {
      return R.reduce(
        (completeStatuses, workflowStaus: Object) => {
          if (!workflowStaus.active) {
            return [...completeStatuses, workflowStaus.id];
          }
          return completeStatuses;
        },
        [],
        workflow?.status || []
      );
    }
    return [];
  }
);

export const getWorkflowStatuses = (state: WorkflowState, id: WorkflowId) => {
  return getCustomStatus(state, id).map(R.prop("id"));
};

export const getBuilderWorkflowId = (state: WorkflowState) =>
  state.builderDialog.id;

export const getChecklistFieldPosition = (state: WorkflowState) =>
  state.builderDialog.checklistFieldSettings.position;

export const getWorkflowBuilderShown = (state: WorkflowState) =>
  state.builderDialog.show;

export const getIfFormFieldsVisible = (
  state: WorkflowState,
  fieldId: FieldId
) => {
  const formFieldsVisibility = state.formFieldsVisibility[`${fieldId}`];
  return R.any(R.equals(true), R.values(formFieldsVisibility));
};

export const getFormFieldsVisibility = (state: WorkflowState) =>
  state.formFieldsVisibility;

export const selectInstancesById = (state: WorkflowState) =>
  state.instancesById;

export const getInstancesById = createSelector(
  [selectInstancesById],
  instancesById => instancesById
);

const selectAutoNo = (_, props) => props.autoNo;

const selectAutoNoAndParentId = (_, autoNo, parentId) => ({ autoNo, parentId });

const selectAutoNoAndColumnId = (_, autoNo, columnId) => ({ autoNo, columnId });

export const getInstancesByAutoNo = createSelector(
  [selectInstancesById, selectAutoNo],
  (instancesById, autoNo) => instancesById[autoNo]
);

export const getExpandedRowsLength = createSelector(
  [getInstancesByAutoNo, selectAutoNoAndParentId],
  (instancesByAutoNo, { autoNo, parentId }) => {
    const relevantData = instancesByAutoNo?.[autoNo];

    if (!relevantData) {
      return 1;
    }

    return (
      relevantData[`${parentId}-meta`]?.length ??
      relevantData[parentId]?.length ??
      1
    );
  }
);

export const getChatroomByColumnId = createSelector(
  [getInstancesByAutoNo, selectAutoNoAndColumnId],
  (instancesByAutoNo, { autoNo, columnId }) => {
    const relevantData = instancesByAutoNo?.[autoNo];

    if (!relevantData) {
      return null;
    }

    return relevantData[columnId];
  }
);

export const getWorkflowInstances = createSelector(
  [getInstancesById],
  instancesById => {
    return R.keys(instancesById)
      .sort((a, b) => parseInt(b) - parseInt(a))
      .map(autoNo => instancesById[autoNo]);
  }
);

export const getLoadingInstances = (state: WorkflowState) =>
  state.loadingInstances;

export const getAllPrincipalChecklistFields = (state: WorkflowState) =>
  state.principalChecklist.fields;

export const getCSVFieldMappings = (
  state: WorkflowState,
  templateId: ProcessTemplateId
) => state?.csvFieldMappings[templateId]?.mappedFields;

export const getCSVFieldMappingsLoading = (
  state: WorkflowState,
  templateId: ProcessTemplateId
) => state?.csvFieldMappings[templateId]?.loading;

export const getCSVFieldMappingsFile = (
  state: WorkflowState,
  templateId: ProcessTemplateId
) => state?.csvFieldMappings[templateId]?.file;

export const getChecklistLayoutSettings = (state: WorkflowState) =>
  state.builderDialog.layout;

export const getLayoutSettings = createSelector(
  [getWorkflow],
  (workflow: Workflow) => {
    return workflow.layout || {};
  }
);

export const getCustomWorkflowStatuses =
  (state: WorkflowState) => (workflowId: WorkflowId) =>
    state?.byId[workflowId]?.status || [];

export const getAdditionalFilters = (state: WorkflowState) =>
  state.additionalFilters;
export const getAllRecords = (state: WorkflowState): AllRecords =>
  state.allRecords || {};

const selectAreInstancesFormatted = (state: WorkflowState) =>
  state.areInstancesFormatted;

export const getAreInstancesFormatted = createSelector(
  [selectAreInstancesFormatted],
  areInstancesFormatted => {
    return areInstancesFormatted;
  }
);

export const getChecklistId = createSelector(
  [getWorkflowInstanceFilter, getWorkflowsById],
  (instanceFilter, workflowsbyId) => {
    const workflowId: WorkflowInstanceFilter = instanceFilter.id;
    return workflowsbyId[workflowId]?.checklists[0];
  }
);

export const getWorkflowAdditionalFilterChartId = (state: WorkflowState) =>
  state.additionalFilters.chartId;

export const getWorkflowChecklistId = (state: WorkflowState, id: WorkflowId) =>
  state.byId[id]?.checklists[0];

export const getCurrentEmbeddedFieldDetails = createSelector(
  [getPrincipalChecklistFieldsList],
  checklist => {
    const linkedAndConversationFields = R.filter(
      field =>
        field.type === "link" ||
        field.type === "childConversation" ||
        field.type === "conversation",
      checklist
    );

    try {
      return linkedAndConversationFields.reduce((acc, linkedField) => {
        const embeddedFields = linkedField.settings.fields;

        return embeddedFields
          ? [
              ...acc,
              ...embeddedFields.map(field => ({
                id: field,
                checklistFieldLabel: linkedField.label,
                checklistFieldId: linkedField.id,
                linkedProcess: linkedField.settings.workflow
              }))
            ]
          : [...acc];
      }, []);
    } catch (e) {
      console.error(e);
    }
  }
);

export const getAdditionalFiltersChartId = (state: WorkflowState) =>
  state.additionalFilters.chartId;

export const getWorkflowNextSeqNo = (state: WorkflowState) => state.nextSeqNo;

export const getIsDataProcess = (state: WorkflowState, id: ?WorkflowId) => {
  if (id == null) {
    return false;
  }
  return state.byId[`${id}`]?.["data"] ?? false;
};
