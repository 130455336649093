// @flow

import React from "react";

import { Header as StyledHeader, Button, ButtonActive } from "./styles";
import Search from "./Search";
import InstantSearch from "./InstantSearch";
import Icon from "src/components/Icon";
import InlineIcon from "src/icons";
import Upload from "src/containers/chatroom/UploadCurrentRoom";
import * as colors from "src/styles/constants/colors";

import { iconSize } from "src/styles/constants/size";

type Props = {
  text: string,
  button?: string,
  addItem?: boolean,
  type?: ?string,
  instant?: boolean,
  buttonColor?: string,
  secondary?: boolean,
  secondaryPlaceholder?: string,
  handleChange: Function,
  handleCreate?: Function,
  handleSecondaryAction?: Function
};

const Header = ({
  text,
  button,
  type = null,
  instant = false,
  secondary = false,
  secondaryPlaceholder = "",
  handleSecondaryAction = () => {},
  addItem = true,
  buttonColor = colors.active,
  handleChange,
  handleCreate = () => {}
}: Props) => (
  <StyledHeader buttonColor={buttonColor}>
    {instant ? (
      <InstantSearch
        search={text}
        handleSearch={handleChange}
        instant={instant}
      />
    ) : (
      <Search search={text} handleSearch={handleChange} instant={instant} />
    )}

    {addItem ? (
      <div>
        {secondary ? (
          <ButtonActive onClick={handleSecondaryAction}>
            <Icon icon="plusCircle" size={iconSize.space_l} />
            {secondaryPlaceholder}
          </ButtonActive>
        ) : null}

        {type === "file" ? (
          <Upload
            location="manage-file-view"
            render={openFileBrowser => (
              <Button onClick={openFileBrowser}>
                <Icon icon="plusCircle" size={iconSize.space_l} />
                {button}
              </Button>
            )}
          />
        ) : (
          <Button
            type="button"
            onClick={handleCreate}
            data-cy="createProcessTemplate"
          >
            {buttonColor === colors.active ? (
              <Icon icon="plusCircle" size={iconSize.space_l} />
            ) : (
              <InlineIcon type="removeCircle" size={iconSize.space_l} />
            )}
            {button}
          </Button>
        )}
      </div>
    ) : null}
  </StyledHeader>
);

export default Header;
