// @flow

import React from "react";

import { Row as StyledRow } from "../styles";
import { Count } from "./styles";
import Title from "./Title";
import Time from "./Time";
import Creator from "./Creator";

import type { Workflow } from "src/types";

type Props = {
  lastCreated: ?number,
  workflow: Workflow,
  handleEdit: Function,
  handleDelete: Function
};

const Row = ({ lastCreated, workflow, handleEdit, handleDelete }: Props) => (
  <StyledRow last={lastCreated === workflow.id}>
    <Title workflow={workflow} handleEdit={handleEdit} />
    <Count>{workflow.activeCount}</Count>
    <Count>{workflow.invocationCount}</Count>
    <Time time={workflow.createdAt} />
    <Time time={workflow.updatedAt} />
    <Creator
      workflow={workflow}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
    />
  </StyledRow>
);

export default Row;
