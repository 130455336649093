// @flow

import { connect } from "react-redux";
import React from "react";
import Link from "redux-first-router-link";
import moment from "moment";
import { Text } from "@chakra-ui/react";

import { Title as StyledTitle, New, Privacy, IconContainer } from "./styles";
import InlineIcon from "src/components/Icon";
import Icon from "src/icons";
import * as atypes from "src/constants/actionTypes";
import * as colors from "src/styles/constants/colors";
import { iconSize } from "src/styles/constants/size";
import * as status from "src/constants/status";
import { viewProcessDetails } from "src/actions/workflows";
import { toggleWorkflowDeletionModal } from "src/actions/modal";

import type { Workflow } from "src/types";

type Props = {
  workflow: Workflow,
  handleEdit: Function,
  _viewProcessDetails: Function,
  toggleWorkflowDeletionModal: Function
};

const Title = ({
  workflow,
  handleEdit,
  _viewProcessDetails,
  toggleWorkflowDeletionModal
}: Props) => {
  const trackViewProcess = () => {
    _viewProcessDetails(workflow.id, workflow.title);
  };

  const showWorkflowDeletionModal = e => {
    e.preventDefault();
    toggleWorkflowDeletionModal(true, workflow.id);
  };

  return (
    <StyledTitle onClick={trackViewProcess}>
      <Link
        to={{
          type: atypes.SET_PROCESS_REQUEST,
          meta: {
            query: {
              id: workflow.id,
              sort: []
            }
          }
        }}
      >
        <Text as="span" isTruncated>
          {workflow.title}
        </Text>
        <Privacy>
          {workflow.privacy !== "none" ? <Icon type="lock" /> : null}
        </Privacy>

        {workflow?.settings?.editable === false ? (
          <></>
        ) : (
          <IconContainer>
            <InlineIcon
              icon="edit"
              onClick={e => handleEdit(workflow, e)}
              size={iconSize.space_m}
              color={colors.primary}
            />
          </IconContainer>
        )}
        <IconContainer>
          <InlineIcon
            icon="remove"
            onClick={showWorkflowDeletionModal}
            size={iconSize.space_m}
          />
        </IconContainer>

        {workflow.draft ? <New status={status.DRAFT}>DRAFT</New> : null}
        {workflow.invocationCount === 0 &&
        moment().diff(workflow.createdAt, "days") === 0 ? (
          <New status={status.NEW}>NEW</New>
        ) : null}
      </Link>
    </StyledTitle>
  );
};

export default connect(null, {
  _viewProcessDetails: viewProcessDetails,
  toggleWorkflowDeletionModal
})(Title);
