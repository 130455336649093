// @flow

import styled from "@emotion/styled";
import {
  Editable as ChakraEditable,
  EditableInput as ChakraEditableInput
} from "@chakra-ui/react";

import * as colors from "./constants/colors";
import * as radius from "./constants/radius";
import * as spacing from "./constants/spacing";
import checkbox from "src/img/checkbox.svg";

const Input = styled.input`
  width: 100%;
  border-radius: ${radius.small};
  min-width: 232px;
  padding: ${spacing.space_inset_default};
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
  ::placeholder {
    color: ${colors.secondary};
  }
  :hover,
  :focus {
    outline: none;
    border: 1px solid ${colors.grey5};
  }
  :read-only {
    background: ${colors.grey1};
    cursor: not-allowed;
  }
`;

const Inline = styled.input`
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
  padding: ${spacing.space_xs} ${spacing.space_s} ${spacing.space_xs}
    ${spacing.space_s};
  ::placeholder {
    color: ${colors.secondary};
  }
  :hover,
  :focus {
    outline: none;
    border: 1px solid ${colors.grey5};
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: vertical;
  border-radius: ${radius.small};
  min-width: 232px;
  min-height: 73px;
  padding: ${spacing.space_inset_default};
  background: ${colors.brightLight};
  border: 1px solid ${colors.grey4};
  ::placeholder {
    color: ${colors.secondary};
  }
  :hover,
  :focus {
    border: 1px solid ${colors.secondary};
    outline: none;
  }
`;

const Checkbox = styled.div`
  display: inline-block;
  margin-right: ${spacing.space_m};
  label {
    padding: 0px;
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    :focus {
      outline: none;
    }
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      & + div {
        display: inline-block;
        height: ${spacing.space_l};
        width: ${spacing.space_l};
        border: solid 1px ${colors.grey4};
        background: ${props =>
          props.disabled ? colors.grey2 : colors.brightLight};
        border-radius: ${radius.small};
        position: relative;
        top: ${spacing.space_s};
      }
    }
    input:checked {
      & + div {
        background-image: url(${checkbox});
        background-repeat: no-repeat;
        background-size: contain;
        border: none;
      }
    }
  }
`;

const TextInputWithBorders = styled(Input)`
  outline-offset: -1px;
  border: 1px solid ${colors.secondary};
  &::placeholder {
    color: #969696;
    font-weight: 300;
  }
`;

const SmallText = styled(TextInputWithBorders)`
  width: auto;
  min-width: 161px;
  max-width: 100%;
  border: none !important;
  outline: none !important;
`;

const TextWithputBorders = styled(Input)`
  border: none;
`;

const Search = styled(Input)`
  margin: ${spacing.space_m} 0 0 0;
  border-color: ${colors.grey5};
  line-height: 1.35;
`;

const GreyInput = styled(Input)`
  background: ${colors.grey0} !important;
  border: none !important;
  padding-left: 0.813rem;
  border: 1px solid ${colors.brightLight} !important;
  ::placeholder {
    color: ${colors.grey5};
  }
  :focus {
    background: ${colors.brightLight} !important;
    border: 1px solid ${colors.active} !important;
  }
  :hover {
    border: 1px solid ${colors.active} !important;
  }
`;

const Editable = styled(ChakraEditable)`
  span {
    padding-bottom: 0;
  }

  span[hidden] {
    display: none;
  }
`;

const EditableInput = styled(ChakraEditableInput)`
  font-size: 14px;
  font-weight: 600;

  box-shadow: none !important;
  border-style: solid;
  border-color: ${colors.inkLightest};

  :focus {
    box-shadow: none;
    border-color: ${colors.inkLightest};
  }
`;

export {
  Input,
  SmallText,
  TextInputWithBorders,
  TextArea,
  Checkbox,
  TextWithputBorders,
  Search,
  Inline,
  GreyInput,
  Editable,
  EditableInput
};
