import React from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import localForage from "localforage";

import segmentApiKey from "src/config/segment";

import { techsupportEmail } from "src/config/sentry";
import { AppCrash, CrashDetails } from "src/styles";
import { Button } from "src/styles/buttons";

const objAgent = navigator.userAgent;
let browserName = navigator.appName;
const vendor = navigator.vendor;
let versionNumber = "" + parseFloat(navigator.appVersion);
let offSetVersion;

const isInternetExplorer =
  objAgent.indexOf("MSIE") !== -1 || objAgent.indexOf("Trident/") !== -1;
const isFirefox = objAgent.indexOf("Firefox") !== -1;
const isChrome = objAgent.indexOf("Chrome") !== -1 && vendor === "Google Inc.";
const isSafari =
  objAgent.indexOf("Safari") !== -1 && vendor === "Apple Computer, Inc.";
const isEdge = objAgent.indexOf("Edge") !== -1;
const isOpera = objAgent.indexOf("OP") !== -1;

// In Chrome
if (isChrome) {
  offSetVersion = objAgent.indexOf("Chrome");
  browserName = "Chrome";
  versionNumber = objAgent.substring(offSetVersion + 7).slice(0, 4);
}

// In Firefox
if (isFirefox) {
  offSetVersion = objAgent.indexOf("Firefox");
  browserName = "Firefox";
  versionNumber = objAgent.substring(offSetVersion + 8).slice(0, 4);
}

// In Internet Explorer
if (isInternetExplorer) {
  offSetVersion = objAgent.indexOf("MSIE");
  browserName = "Microsoft Internet Explorer";
  versionNumber = "";
}

// In Safari
if (isSafari) {
  offSetVersion = objAgent.indexOf("Safari");
  browserName = "Safari";
  versionNumber = objAgent.substring(offSetVersion + 7).slice(0, 4);
}

// In Edge
if (isEdge) {
  offSetVersion = objAgent.indexOf("Edge") || objAgent.indexOf("Trident/");
  browserName = "Edge";
  versionNumber = objAgent.substring(offSetVersion + 5).slice(0, 4);
}

// In Opera
if (isOpera) {
  offSetVersion = objAgent.indexOf("OP");
  browserName = "Opera";
  versionNumber = objAgent.substring(offSetVersion + 4).slice(0, 4);
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidMount() {
    try {
      if (segmentApiKey) {
        window.analytics.load(segmentApiKey);
      }
    } catch (err) {
      console.error("Error initializing segment: ", err);
    }
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error, { extra: info });
  }

  reloadWindow = async () => {
    try {
      const orgId = sessionStorage.getItem("lastOrg");

      if (orgId) {
        // Clearing indexdb
        await localForage.setItem(`chatrooms-${orgId}`, JSON.stringify([]));
        await localForage.setItem(`lastFetched-${orgId}`, "");
      }
    } catch (error) {
      console.log("unable to clear database");
    }
    window.location.reload();
  };

  render() {
    if (!this.state.hasError) return this.props.children;

    return (
      <AppCrash>
        <p>We&apos;re sorry — something&apos;s gone wrong.</p>
        <p>
          Our team has been notified, but&nbsp;
          <a
            href={`mailto:${techsupportEmail}`}
            onClick={this.showSentryReportDialog}
            className="text-link"
          >
            let us know
          </a>
          &nbsp;what happened.
        </p>
        <CrashDetails>
          <li>
            <strong>Browser:</strong>{" "}
            <span>{browserName + "  " + versionNumber}</span>
          </li>
          <li>
            <strong>Version:</strong> <span>{process.env.VERSION}</span>
          </li>
          <li>
            <strong>UID:</strong> <span>{window.currentUserUID}</span>
          </li>
          <li>
            <strong>URL:</strong> <span>{window.location.href}</span>
          </li>
          <li>
            <strong>Org:</strong> <span>{window.currentOrgId}</span>
          </li>
        </CrashDetails>
        <Button onClick={this.reloadWindow}>Try again</Button>
        {window.crashReport && (
          <code>{JSON.stringify(window.crashReport)}</code>
        )}
      </AppCrash>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
