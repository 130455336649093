// @flow

import React from "react";
import { Container, Checkbox as StyledCheckbox, Label } from "./styles";
import Icons from "src/icons";

type Props = {
  checked: boolean,
  onChange: Function,
  label?: string,
  isDisabled?: boolean,
  height?: string
};

const Checkbox = ({
  checked,
  label,
  onChange,
  isDisabled = false,
  height = "20px"
}: Props) => {
  const handleChange = () => {
    if (isDisabled) return;
    onChange(!checked);
  };
  return (
    <Container onClick={handleChange} disabled={isDisabled} height={height}>
      <StyledCheckbox checked={checked} onChange={() => {}} />
      {checked ? (
        <Icons type="activeCheckbox" />
      ) : (
        <Icons type="defaultCheckbox" />
      )}

      <Label>{label}</Label>
    </Container>
  );
};

export default Checkbox;
