// @flow

import * as React from "react";
import { Button as StyledButton } from "./styles";

import Icon from "src/icons";

type Props = {
  isLoading?: boolean,
  variant: string,
  children?: React.Node,
  disabled?: boolean,
  handleClick?: Function
};

const Button = ({
  isLoading = false,
  variant,
  children = null,
  disabled = false,
  handleClick = () => {}
}: Props) => {
  if (isLoading) {
    return (
      <StyledButton variant={variant} disabled>
        <Icon type="spinner" />
      </StyledButton>
    );
  }

  return (
    <StyledButton variant={variant} disabled={disabled} onClick={handleClick}>
      {children || null}
    </StyledButton>
  );
};

export default Button;
