// @flow

import React from "react";
import { SortableContainer } from "react-sortable-hoc";

import FieldSelect from "./FieldSelect";
import { Label, FormGroup } from "../styles";
import { AddField } from "./styles";
import Icon from "src/icons";
import SelectProcess from "src/containers/workflow/select";
import { VStack } from "@chakra-ui/react";

const FieldList = SortableContainer(({ children }) => {
  return <VStack alignItems="start">{children}</VStack>;
});

type Props = {
  workflow: ?number,
  fields: Array<number>,
  handleTemplate: Function,
  addField: Function,
  handleField: Function,
  removeField: Function,
  onSortFields: Function
};

const Workflow = ({
  workflow,
  fields,
  handleTemplate,
  addField,
  handleField,
  removeField,
  onSortFields
}: Props) => {
  return (
    <>
      <FormGroup>
        <Label>Process</Label>
        <SelectProcess value={workflow} handleChange={handleTemplate} />
      </FormGroup>

      <FormGroup>
        <Label>EMBEDDED FIELDS</Label>
        <FieldList useDragHandle onSortEnd={onSortFields}>
          {fields.map((field: ?number, index) => (
            <FieldSelect
              index={index}
              itemIndex={index}
              key={`item-${index}`}
              handleField={handleField}
              removeField={removeField}
              fields={fields}
              value={field}
            />
          ))}
        </FieldList>
      </FormGroup>

      <AddField
        data-cy="addEmbeddedFieldButton"
        type="button"
        disabled={!workflow}
        onClick={addField}
      >
        <Icon type="hollowPlus" />
        <span>Add Embeded field</span>
      </AddField>
    </>
  );
};

export default Workflow;
