import styled from "styled-components";
import * as colors from "src/styles/constants/colors";

export const Button = styled.button`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }
  &:disabled {
    opacity: 0.3
  }
  word-wrap: break-word;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
  margin: 0;
  overflow: visible;
  text-transform: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: 0.5rem;
  font-weight: 500;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-duration: 200ms;
  height: 2rem;
  min-width: 118.16px;
  font-size: 0.875rem;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  background: ${colors.blue};
  color: ${colors.brightLight};

  > svg {
    animation: rotate 0.6s linear infinite;
  }
`;
