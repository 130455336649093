// @flow

import React, { Component } from "react";
import * as R from "ramda";

import Row from "./Row";
import { sortColumn } from "src/utils";

import type { Workflow } from "src/types";

type Props = {
  sort: string,
  ascending: boolean,
  lastCreated: ?number,
  matches: Array<Workflow>,
  handleEdit: Function,
  handleDelete: Function
};

class Body extends Component<Props> {
  tableBody: Object;
  componentDidMount() {
    if (this.tableBody) {
      this.tableBody.current.focus();
    }
  }

  render() {
    const {
      sort,
      ascending,
      matches,
      handleEdit,
      lastCreated,
      handleDelete
    } = this.props;

    return (
      <tbody tabIndex="0" ref={this.tableBody}>
        {R.map(
          workflow => (
            <Row
              key={workflow.id}
              workflow={workflow}
              lastCreated={lastCreated}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ),
          sortColumn(sort, ascending, matches)
        )}
      </tbody>
    );
  }
}

export default Body;
